.cdv {
    border-radius: 5px;
    background-color: #0C1745;
    color: white;
    padding-bottom: 5%;
    padding-top: 5%;
    margin-top: 5%;
    margin-left: 20%;
    margin-right: 20%;
}

.share
{
    align-items: center;
    justify-content: center;
}