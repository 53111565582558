@media screen and (max-width: 640px) {
  .cdv_user {
    width: 100vw;
    margin-bottom: 10vh;
  }
}

@media screen and (min-width: 640px) {
    .cdv_user {
      margin-top: 5rem;
      margin-left: 30px;
      width: 400px;
      height: calc(100vh - 30vh);
      overflow-y: scroll;
      overflow-x: hidden;
      top: 30px;
      left: 100px;
    }
  }
  