@media screen and (min-width: 641px) {
.body_cdv {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    center / cover no-repeat url("../images/factory40.jpg");
  padding-bottom: 5%;
}

.create_cdv {
  margin: auto;
  justify-content: center;
  height: 500px;
  overflow-y: auto;
}

.cdv_card {
  margin: auto;
  width: 400px;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 8%;
  bottom: 30px;
}
}
@media screen and (max-width: 640px) {
  .body_cdv {
    
    display: inline;
    justify-content: space-between;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      center / cover no-repeat url("../images/factory40.jpg");
    padding-bottom: 5%;
  }

  .create_cdv {
    padding-top: 10%;
    margin: auto;
    justify-content: center;
  }

  .cdv_card {
      border: 1px solid black;
      border-radius: 5px;
    margin: auto;
    width: 400px;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 8%;
    margin-bottom: 8%;
  }
}
